import React from 'react';
import fetch from 'isomorphic-fetch';

export async function getData({ uri, orderQueryBy, offset, limit }) {
  return await fetch(
    `${uri}?$order=${orderQueryBy}&$offset=${offset}&$limit=${limit}`,
  )
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong ...');
    })
    .then(data => ({
      data: [...data],
      loadedData: true,
      resultsReturned: data.length,
    }));
}

export async function getMoreData({ ...args }) {
  const { uri, orderQueryBy, offset, limit } = args;
  const newOffset = offset + limit;
  getData({ uri, orderQueryBy, offset: newOffset, limit }).then(data => ({
    data,
    offset: newOffset,
  }));
}

// <GetDataButton getData={this.getMore} haveAllData={haveAllData}/>
export const GetDataButton = (...props) => {
  if (props.haveAllData) {
    return <p>This is the entire data set</p>;
  }
  return (
    <button onClick={e => props.getData}>Would you like more data?</button>
  );
};
