/** @jsx jsx */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';
import { countBy, forIn, max, min, sum, uniq } from 'lodash';
import * as d3 from 'd3';
import { jsx, css, keyframes } from '@emotion/core';
import animate from 'animate.css';

import { getData, GetDataButton } from '../utils/AustinData';
import DataPage from '../templates/DataPage';
import {
  HistogramChart,
  BarChart,
  ScatterChart,
  StyledSVG,
  SVG,
} from '../components/Charts';
import Bar from '../components/Bar';
import { AnimateBounce } from '../components/Bounce.jsx';
import { Heading, AnimateRotate } from '../components/SpinningText.jsx';
// https://github.com/open-austin/project-ideas/issues/112

export default class AffordableHousing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      haveAllData: false,
      loadedData: false,
      limit: 1000,
      offset: 0,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.data === nextState.data) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    getData({
      uri: this.props.dataUri,
      orderQueryBy: this.props.orderQueryBy,
      offset: this.state.offset,
      limit: this.state.limit,
    }).then(response =>
      this.setState({
        data: response.data,
        offset: response.resultsReturned,
        loadedData: response.loadedData,
      }),
    );
  }

  componentWillUnmount() {
    this.setState({
      data: null,
      haveAllData: false,
      loadedData: false,
      limit: 1000,
      offset: 0,
    });
  }

  getMore() {
    const { offset, limit } = this.state;
    let newOffset;
    let haveAllData;
    getData({
      uri: this.props.dataUri,
      orderQueryBy: this.props.orderQueryBy,
      offset,
      limit,
    }).then(response => {
      if (response.resultsReturned < offset) {
        newOffset = response.resultsReturned;
        haveAllData = true;
      } else {
        newOffset = offset + response.resultsReturned;
        haveAllData = false;
      }
      this.setState(prevState => ({
        data: [...prevState.data, ...response.data],
        offset: newOffset,
        haveAllData,
      }));
    });
  }

  render() {
    // look into affordability start/end years
    // contrast Market Rate Units with Total Units and Total Affordable Units
    let content;
    const { data, haveAllData, loadedData, limit, offset } = this.state;
    const nearHighFrequencyTransit = [];
    const withinThreeQuartersMile = [];
    const councilDistricts = [];
    const unitsPerDevelopment = [];
    const zipCodes = [];
    const barChartData = [];

    const bounce = keyframes`
      from, 20%, 53%, 80%, to {
      transform: translate3d(0,0,0);
      }

      40%, 43% {
        transform: translate3d(0, -30px, 0);
      }

      70% {
        transform: translate3d(0, -15px, 0);
      }

      90% {
        transform: translate3d(0,-4px,0);
      }
    `;

    if (loadedData) {
      const Button = () => {
        if (haveAllData) {
          return '';
        }
        return (
          <p>
            <button onClick={() => this.getMore()}>
              Would you like more data?
            </button>
          </p>
        );
      };
      data.map(d => {
        if (d._1_4_mile_from_high_frequency_transit === 'Yes') {
          nearHighFrequencyTransit.push(
            d._1_4_mile_from_high_frequency_transit,
          );
        }
        if (d._3_4_mile_from_local_transit === 'Yes') {
          withinThreeQuartersMile.push(d._3_4_mile_from_local_transit);
        }
        councilDistricts.push(d.council_district);
        unitsPerDevelopment.push(d.total_affordable_units);
        zipCodes.push(d.zip_code);
      });

      const zipCodeCount = countBy(zipCodes);

      forIn(zipCodeCount, (count, label) => {
        barChartData.push({
          x: label,
          y: count,
          label,
        });
      });

      const numberOfZipCodes = uniq(zipCodes).length;

      content = (
        <article>
          <AnimateBounce>
            You are viewing&nbsp;
            {data.length}
            {' '}
records
          </AnimateBounce>
          <Button />
          <p>
            There are&nbsp;
            {numberOfZipCodes}
            {' '}
zip codes in this set
          </p>
          <p>
            There are 
            {' '}
            {nearHighFrequencyTransit.length}
            {' '}
places near high
            frequency transit in this set
          </p>
          <p>
            There are 
            {' '}
            {withinThreeQuartersMile.length}
            {' '}
places within 3/4 miles
            of transit in this set
          </p>
          <h3>
            Distribution of the number of affordable units per development
          </h3>
          <HistogramChart
            data={unitsPerDevelopment}
            xLabel="Units Per Development"
            yLabel="Count"
          />
          <h3>Project count by Zip Code</h3>
          <StyledSVG>
            <BarChart
              data={barChartData}
              xLabel="Units Per Development"
              yLabel="Count"
            />
          </StyledSVG>
          {/*   

          <svg css={css` background: "green"; height: 400; width: 600 `}

            <p>The fewest affordable units are in {}</p>
          */}
        </article>
      );
    } else {
      content = 'Something went wrong';
    }

    return <DataPage loadedData={loadedData}>{content}</DataPage>;
  }
}

AffordableHousing.defaultProps = {
  dataUri: 'https://data.austintexas.gov/resource/2drp-3fg9.json',
  orderQueryBy: 'zip_code',
};
