/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core';

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -5px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`;

export const AnimateBounce = props => (
  <div
    css={css`
      animation: ${bounce} 1s ease;
    `}
  >
    {props.children}
  </div>
);
