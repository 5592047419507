import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';

export default class DataPage extends Component {
  // componentWillMount() {
  //   this.getData();
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state.data === nextState.data) {
  //     return false;
  //   }
  //   return true;
  // }

  render() {
    const { children, loadedData } = this.props;
    if (!loadedData) {
      return <Layout location={this.props.location}>loading...</Layout>;
    }

    return <Layout location={this.props.location}>{children}</Layout>;
  }
}

DataPage.propTypes = {
  loadedData: PropTypes.bool,
};

DataPage.defaultProps = {
  loadedData: false,
};
