/** @jsx jsx */
import { memoize } from 'lodash';
// import memoize from 'lodash';
import React from 'react';
import { jsx, css, keyframes } from '@emotion/core';

const logo = `https://picsum.photos/g/200`;

const basic = css`
  color: green;
`;

const important = css`
  color: hotpink;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const AnimateRotate = ({ children }) => (
  <div
    css={css`
      animation: ${rotate} 1s ease 1;
    `}
  >
    {children}
  </div>
);

// export const SomeComponent = props => (
//   <div css={[basic, props.important && important]} />
// )

export const Heading = memoize(
  props => (
    <div className="heading">
      <div className="App-header">
        <img src={logo} alt="logo" />
        <h2 css={basic}>{props.title}</h2>
      </div>
    </div>
  ),
  props => props.title,
);
